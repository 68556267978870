class POIMap {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.initMarkers = this.root.getAttribute('data-init-markers');
        this.dataMarkers = JSON.parse(this.root.getAttribute('data-markers'));
        this.mapEl = this.root.querySelector('[data-poi-map-map]');
        this.panoramicEl = this.root.querySelector('[data-poi-map-panoramic]');
        this.typeEl = this.root.querySelectorAll('[data-poi-map-type]');
        this.viewEl = this.root.querySelectorAll('[data-poi-map-view]');

        this.legendEl = this.root.querySelector('[data-poi-map-legend]');
        this.legendMoreEl = this.root.querySelector(
            '[data-poi-map-legend-more]'
        );

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));

        this.markers = [];
        this.infoWindows = [];
        this.currTypes = [];
        this.currView = 'map';

        this.investmentMarker = this.settings.investment_marker;

        return true;
    }

    init() {
        this.initGoogleMap();
        this.setTypesEl();
        this.setViewsEl();
        this.setLegendMore();
    }

    setTypesEl() {
        if (!this.typeEl) return;

        this.onType = this.onTypeClick.bind(this);
        this.typeEl.forEach(item =>
            item.addEventListener('click', this.onType)
        );
    }

    setLegendMore() {
        if (!this.legendMoreEl || !this.legendEl || !this.typeEl) return;

        this.onLegendMore = this.onLegendMoreClick.bind(this);
        this.legendMoreEl.addEventListener('click', this.onLegendMore);
    }

    setViewsEl() {
        if (!this.viewEl) return;

        this.onView = this.onViewClick.bind(this);
        this.viewEl.forEach(item =>
            item.addEventListener('click', this.onView.bind(this))
        );
    }

    onLegendMoreClick() {
        this.legendEl.classList.add('-expand');
        this.typeEl.forEach(item => {
            item.classList.remove('-hide');
        });
    }

    onTypeClick(event) {
        const { currentTarget } = event;
        const typeID = parseInt(
            currentTarget.getAttribute('data-poi-map-type')
        );

        const itemIndex = this.currTypes.indexOf(typeID);
        itemIndex === -1
            ? this.currTypes.push(typeID)
            : this.currTypes.splice(itemIndex, 1);

        if (this.currTypes.includes(typeID)) {
            currentTarget.classList.add('-active');
        } else {
            currentTarget.classList.remove('-active');
        }

        if (this.currTypes.length > 0) {
            this.loadPoints();
        } else {
            this.clearGoogleAssets();
            this.setInvestmentMarker();
        }
    }

    onViewClick(event) {
        const { currentTarget } = event;
        const view = currentTarget.getAttribute('data-poi-map-view');

        this.currView = view;

        this.resetViews();
        this.setActiveItem(currentTarget);
        this.setMapView(this.currView);
    }

    setMapView(view) {
        if (view === 'roadmap') {
            this.mapEl.classList.remove('-hide');
            if (this.panoramicEl) this.panoramicEl.classList.add('-hide');
        }

        if (view === 'satellite') {
            this.mapEl.classList.add('-hide');
            if (this.panoramicEl) this.panoramicEl.classList.remove('-hide');
        }
    }

    async initGoogleMap() {
        if (!this.mapEl) return;

        let position = { lat: 52.390887, lng: 16.8608581 };
        if (this.investmentMarker)
            position = {
                lat: parseFloat(this.investmentMarker.lat),
                lng: parseFloat(this.investmentMarker.lon),
            };

        if (this.initMarkers !== null && this.dataMarkers.length > 0) {
            position = {
                lat: parseFloat(this.dataMarkers[0].lat),
                lng: parseFloat(this.dataMarkers[0].lon),
            };
        }

        const { Map } = await google.maps.importLibrary('maps');

        this.googleMapInstance = new Map(this.mapEl, {
            zoom: 14,
            center: position,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            clickableIcons: false,
        });

        this.setInvestmentMarker();
        this.setInitMarkers();
    }

    async loadPoints() {
        const formData = new FormData();
        if (this.currTypes.length > 0) formData.append('types', this.currTypes);

        formData.append('investment_type', this.settings.investment_type);
        formData.append('investment', this.settings.investment);

        const params = new URLSearchParams(formData);
        const url = new URL(`${home_url}/wp-json/poi-map/v1/get`);
        url.search = params.toString();

        const request = await fetch(url, {
            method: 'GET',
        });

        const res = await request.json();
        const { points } = res;

        this.setMarkers(points);
    }

    clearGoogleAssets() {
        if (!this.markers || this.markers.length === 0) return;
        for (let i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }
        this.markers = [];
        this.infoWindows = [];
    }

    setInvestmentMarker() {
        if (!this.googleMapInstance || !this.investmentMarker) return;

        let { lat, lon, name, marker_url } = this.investmentMarker;
        lat = parseFloat(lat);
        lon = parseFloat(lon);

        const htmlContent = this.setTooltipContent(this.investmentMarker, true);
        const marker = new google.maps.Marker({
            position: { lat: lat, lng: lon },
            map: this.googleMapInstance,
            icon: marker_url,
        });

        const infowindow = new google.maps.InfoWindow({
            shouldFocus: false,
            content: htmlContent,
            ariaLabel: name,
        });

        this.infoWindows.push(infowindow);

        marker.addListener('click', e => {
            this.infoWindows.forEach(item => item.close());

            setTimeout(() => {
                infowindow.open({
                    anchor: marker,
                    map: this.googleMapInstance,
                });
            }, 200);
        });

        this.markers.push(marker);
    }

    setInitMarkers() {
        if (this.initMarkers === null) return;
        this.setMarkers(this.dataMarkers);
    }

    async setMarkers(coords) {
        if (!this.googleMapInstance) return;

        this.clearGoogleAssets();
        this.setInvestmentMarker();

        if (coords.length > 0) {
            coords.forEach(item => {
                let { lat, lon, name, marker_url } = item;

                if (lat !== '' && lon !== '') {
                    lat = parseFloat(lat);
                    lon = parseFloat(lon);

                    const htmlContent = this.setTooltipContent(item);
                    const marker = new google.maps.Marker({
                        position: { lat: lat, lng: lon },
                        map: this.googleMapInstance,
                        icon: marker_url,
                    });

                    const infowindow = new google.maps.InfoWindow({
                        shouldFocus: false,
                        content: htmlContent,
                        ariaLabel: name,
                    });

                    this.infoWindows.push(infowindow);

                    marker.addListener('click', e => {
                        this.infoWindows.forEach(item => item.close());

                        setTimeout(() => {
                            infowindow.open({
                                anchor: marker,
                                map: this.googleMapInstance,
                            });
                        }, 200);
                    });

                    this.markers.push(marker);
                }
            });
        }

        var bounds = new google.maps.LatLngBounds();
        if (this.markers.length > 1) {
            for (var i = 0; i < this.markers.length; i++) {
                let latlng = this.markers[i].getPosition();
                bounds.extend(latlng);
            }
            this.googleMapInstance.fitBounds(bounds);
        }
    }

    resetViews() {
        if (!this.viewEl) return;
        this.viewEl.forEach(item => item.classList.remove('-active'));
    }

    setActiveItem(item) {
        if (!item) return;
        item.classList.add('-active');
    }

    setTooltipContent(data, isInvestment = false) {
        let { name, description, logo } = data;

        let htmlContent = '<div class="map-tooltip">';
        if (logo && logo.length > 0)
            htmlContent += `<img src="${logo}" class="map-tooltip__logo" />`;
        if (name && name.length > 0 && !isInvestment)
            htmlContent += `<p class="map-tooltip__name">${name}</p>`;
        if (description && name.length > 0)
            htmlContent += `<p class="map-tooltip__description">${description}</p>`;
        htmlContent += '</div>';

        return htmlContent;
    }
}

function initPOIMaps() {
    const sections = document.querySelectorAll('[data-poi-map]');
    if (!sections) return;

    sections.forEach(item => new POIMap(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initPOIMaps();
} else {
    document.addEventListener('DOMContentLoaded', initPOIMaps);
}
